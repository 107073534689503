import type { FormStep } from "@/components/FormStep.vue";
import type { TypedSchema, useForm } from "vee-validate";
import type { ComputedRef, InjectionKey, Ref } from "vue";
import type { LocationQueryRaw } from "vue-router";
import type { InputOption } from "@/types";
import type { TodoItem } from "@/components/TodoListItem.vue";

export const routingInjectionKey = Symbol() as InjectionKey<{
  updateRouteQuery: (query: LocationQueryRaw) => void;
}>;

export const INPUT_GROUP_KEY = Symbol() as InjectionKey<{
  name: ComputedRef<string>;
  rules: ComputedRef<TypedSchema<unknown, unknown> | undefined>;
  controlled: boolean;
  disabled: ComputedRef<boolean>;
}>;

export const FORM_STEP_KEY = Symbol() as InjectionKey<{
  steps: Ref<Map<string, ComputedRef<FormStep>>>;
  currentStep: ComputedRef<string> | undefined;
  onSuccess: (cb: Parameters<ReturnType<typeof useForm>["handleSubmit"]>[0]) => void;
  onError: (cb: Parameters<ReturnType<typeof useForm>["handleSubmit"]>[1]) => void;
}>;

export const WEII_YEAR_KEY = Symbol() as InjectionKey<{
  year: Ref<number | "all">;
  options: ComputedRef<InputOption<number | "all">[]>;
  updateYear: (year: number | "all") => void;
}>;

export const TODO_ITEM_KEY = Symbol() as InjectionKey<Ref<Map<string, ComputedRef<TodoItem>>>>;
