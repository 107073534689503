import { checkLicenseAndRoute, hasAccess, routeMeta } from "@/utils/routing";
import { AccessLevel, License } from "@/constants";
import type { RouteRecordRaw } from "vue-router";

const EsgLandingPage = () => import("@/views/EsgLandingPage.vue");
const EsgEanCodebook = () => import("@/views/EsgEanCodebook.vue");
const EsgEmissions = () => import("@/views/EsgEmissions.vue");
const EsgEnergyLabels = () => import("@/views/EsgEnergyLabels.vue");
const EsgEnergySavings = () => import("@/views/EsgEnergySavings.vue");
const EsgHolidaysAnalysis = () => import("@/views/EsgHolidaysAnalysis.vue");
const EsgOutOfOfficeAnalysis = () => import("@/views/EsgOutOfOfficeAnalysis.vue");
const EsgSubmeters = () => import("@/views/EsgSubmeters.vue");
const EsgUsage = () => import("@/views/EsgUsage.vue");
const EsgKeyStatistics = () => import("@/views/EsgKeyStatistics.vue");
const EsgConnections = () => import("@/views/EsgConnections.vue");
const EsgWeiiBuilding = () => import("@/views/EsgWeiiBuilding.vue");
// const EsgWeiiPortfolio = () => import("@/views/EsgWeiiPortfolio.vue");

const routes: RouteRecordRaw[] = [
  {
    path: "/buildings/:buildingId/esg",
    name: "EsgLandingPage",
    component: EsgLandingPage,
    props: true,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.BuildingAccessGroupUser
    }),
    beforeEnter: [hasAccess, to => checkLicenseAndRoute(to, "EsgUsage")]
  },
  {
    path: "/reports/ean-codebook",
    redirect: { name: "EsgEanCodebook" }
  },
  {
    path: "/ean-codebook",
    name: "EsgEanCodebook",
    component: EsgEanCodebook,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.LoggedIn
    }),
    beforeEnter: [hasAccess]
  },
  {
    path: "/emissions",
    redirect: { name: "EsgEmissions" }
  },
  {
    path: "/reports/emissions",
    name: "EsgEmissions",
    component: EsgEmissions,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.BuildingUser,
      requiredLicense: License.ESG
    }),
    beforeEnter: [hasAccess]
  },
  {
    path: "/buildings/:buildingId/energy-labels",
    name: "EsgEnergyLabels",
    component: EsgEnergyLabels,
    props: true,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.BuildingAccessGroupUser,
      requiredLicense: License.ESG,
      breadcrumbs: ["building"]
    }),
    beforeEnter: [hasAccess]
  },
  {
    path: "/buildings/:buildingId/energy-savings",
    name: "EsgEnergySavings",
    component: EsgEnergySavings,
    props: true,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.BuildingUser,
      requiredLicense: License.ESG,
      breadcrumbs: ["building"]
    }),
    beforeEnter: [hasAccess]
  },
  {
    path: "/buildings/:buildingId/reports/national-holidays-analysis",
    redirect: { name: "EsgHolidaysAnalysis" }
  },
  {
    path: "/buildings/:buildingId/national-holidays-analysis",
    name: "EsgHolidaysAnalysis",
    component: EsgHolidaysAnalysis,
    props: true,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.BuildingAccessGroupUser,
      requiredLicense: License.ESG,
      breadcrumbs: ["building"]
    }),
    beforeEnter: [hasAccess]
  },
  {
    path: "/buildings/:buildingId/reports/outside-business-hours-analysis",
    redirect: { name: "EsgOutOfOfficeAnalysis" }
  },
  {
    path: "/buildings/:buildingId/outside-business-hours-analysis",
    name: "EsgOutOfOfficeAnalysis",
    component: EsgOutOfOfficeAnalysis,
    props: true,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.BuildingAccessGroupUser,
      requiredLicense: License.ESG,
      breadcrumbs: ["building"]
    }),
    beforeEnter: [hasAccess]
  },
  {
    path: "/buildings/:buildingId/submeters-overview",
    redirect: { name: "EsgSubmeters" }
  },
  {
    path: "/buildings/:buildingId/submeters",
    name: "EsgSubmeters",
    component: EsgSubmeters,
    props: true,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.BuildingAccessGroupUser,
      requiredLicense: License.ESG,
      breadcrumbs: ["building"]
    }),
    beforeEnter: [hasAccess]
  },
  {
    path: "/buildings/:buildingId/energy",
    redirect: { name: "EsgUsage" }
  },
  {
    path: "/buildings/:buildingId/usage",
    name: "EsgUsage",
    component: EsgUsage,
    props: true,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.BuildingAccessGroupUser,
      requiredLicense: License.ESG,
      breadcrumbs: ["building"]
    }),
    beforeEnter: [hasAccess]
  },
  {
    path: "/buildings/:buildingId/key-statistics",
    name: "EsgKeyStatistics",
    component: EsgKeyStatistics,
    props: true,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.BuildingAccessGroupUser,
      requiredLicense: License.ESG,
      breadcrumbs: ["building"]
    }),
    beforeEnter: [hasAccess]
  },
  {
    path: "/buildings/:buildingId/utility-connections",
    name: "EsgConnections",
    component: EsgConnections,
    props: true,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.BuildingAccessGroupUser,
      requiredLicense: License.ESG,
      breadcrumbs: ["building"]
    }),
    beforeEnter: [hasAccess]
  },
  {
    path: "/buildings/:buildingId/weii-score",
    name: "EsgWeiiBuilding",
    component: EsgWeiiBuilding,
    props: true,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.BuildingUser,
      requiredLicense: License.ESG,
      breadcrumbs: ["building"]
    }),
    beforeEnter: [hasAccess]
  } /*,
  {
    path: "/reports/weii-score",
    name: "EsgWeiiPortfolio",
    component: EsgWeiiPortfolio,
    props: true,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.Admin,
      requiredLicense: License.ESG
    }),
    beforeEnter: [hasAccess]
  }*/
];

export default routes;
